/* eslint-disable camelcase */
import API from '../../api';
import { destroyCookie, getCookies } from '../../services/cookies';
import MA from '../../services/marketingAutomation';
import { runEvent } from '../../services/queue';
import {
   FETCH_CART_REQUEST,
   FETCH_CART_SUCCESS,
   FETCH_CART_FAILURE,
   ADD_TO_CART_REQUEST,
   ADD_TO_CART_SUCCESS,
   ADD_TO_CART_FAILURE,
   SET_QUANTITY_CART_FAILURE,
   SET_QUANTITY_CART_SUCCESS,
   SET_CUPON_CODE_SUCCESS,
   SET_CUPON_CODE_FAILURE,
   REMOVE_ITEM_CART_FAILURE,
   REMOVE_ITEM_CART_SUCCESS,
   SHOW_CHANGE_MODAL_CART,
   SHOW_MODAL_CART,
   HIDE_CHANGE_MODAL_CART,
   HIDE_MODAL_CART,
   RESET_PAYMENT,
   ADVANCE_TO_NEXT_REQUEST,
   ADVANCE_TO_NEXT_SUCCESS,
   ADVANCE_TO_NEXT_FAILURE,
   DELETE_CART,
} from './actionTypes';

export const fetchCartRequest = () => ({
   type: FETCH_CART_REQUEST,
});

export const fetchCartSuccess = (payload) => ({
   type: FETCH_CART_SUCCESS,
   payload,
});

export const fetchCartFailure = (errors) => ({
   type: FETCH_CART_FAILURE,
   errors,
});

export const advanceToNextRequest = () => ({
   type: ADVANCE_TO_NEXT_REQUEST,
});

export const advanceToNextSuccess = (payload) => ({
   type: ADVANCE_TO_NEXT_SUCCESS,
   payload,
});

export const advanceToNextFailure = (errors) => ({
   type: ADVANCE_TO_NEXT_FAILURE,
   errors,
});
export const addToCartRequest = () => ({
   type: ADD_TO_CART_REQUEST,
});

export const addToCartSuccess = (payload) => ({
   type: ADD_TO_CART_SUCCESS,
   payload,
});

export const addToCartFailure = (error) => ({
   type: ADD_TO_CART_FAILURE,
   error,
});

export const showChangeModalCart = (payload) => ({
   type: SHOW_CHANGE_MODAL_CART,
   payload,
});
export const hideChangeModalCart = () => ({
   type: HIDE_CHANGE_MODAL_CART,
});

export const setQuantityCartSuccess = (payload) => ({
   type: SET_QUANTITY_CART_SUCCESS,
   payload,
});

export const setQuantityCartFailure = (error) => ({
   type: SET_QUANTITY_CART_FAILURE,
   error,
});

export const setCuponCodeSuccess = (payload) => ({
   type: SET_CUPON_CODE_SUCCESS,
   payload,
});

export const setCuponCodeFailure = (error) => ({
   type: SET_CUPON_CODE_FAILURE,
   error,
});

export const removeItemCartSuccess = (lineItemId, payload) => async (dispatch, getState) => {
   const { tracking, cart } = getState();
   const { line_items } = cart;
   const product = line_items.find((x) => x.id === lineItemId);

   if (product) {
      product.price = product.total;

      // ejecutamos el evento removeFromCart (analytics, pixel)
      runEvent('removeFromCart', product, tracking.ready);
   }

   dispatch({
      type: REMOVE_ITEM_CART_SUCCESS,
      payload,
   });
};

export const removeItemCartFailure = (error) => ({
   type: REMOVE_ITEM_CART_FAILURE,
   error,
});

export const showModalCart = () => ({
   type: SHOW_MODAL_CART,
});

export const hideModalCart = () => {
   return {
      type: HIDE_MODAL_CART,
   };
};

export const resetPayment = () => ({
   type: RESET_PAYMENT,
});

export const fetchCart = () => async (dispatch, getState) => {
   const { cart: oldCart } = getState();
   // Comparar cuando stock es 0

   // Comparar el carrito ya guardado con el nuevo para ver si el length cambia

   // Comparar carrito guardado con el nuevo para saber el precio
   dispatch(fetchCartRequest());

   // revisamos si tiene el cartToken
   const { cartToken } = getCookies();

   if (!cartToken) {
      // si no lo tiene no hacemos la request
      dispatch(fetchCartFailure());
   } else {
      const result = await API.cart.get();

      if (result.success) {
         if (result.data.email && result.data.item_count > 0) {
            MA.add(result.data.email, result.data.token);
         }
         const totalChangesInCart = [];
         if (result.data['has_item_without_stock?']) {
            totalChangesInCart.push(
               'Uno o más artículos de tu carrito no se encuentran disponibles, revisá tu carrito para más información',
            );
         }
         result.data.line_items.some((item) => {
            if (item['price_was_changed?']) {
               totalChangesInCart.push(
                  'Uno o más artículos de tu carrito han tenido modificaciones en su precio, revisá tu carrito para más información.',
               );
               return true;
            }
            return false;
         });
         if (
            result.data.line_items.length > oldCart.line_items.length &&
            oldCart.line_items.length !== 0
         ) {
            totalChangesInCart.push(
               'Tenés uno o más artículos pendientes en tu carrito de compra, revisá tu carrito para más información.',
            );
         }
         if (totalChangesInCart.length === 1) {
            dispatch(showChangeModalCart(totalChangesInCart[0]));
         }
         if (totalChangesInCart.length > 1) {
            dispatch(
               showChangeModalCart(
                  'Uno o más artículos de tu carrito han tenido modificaciones o no se encuentran disponibles, revisá tu carrito para más información.',
               ),
            );
         }
         dispatch(fetchCartSuccess({ ...result.data, exchangeCart: false }));
      } else {
         dispatch(fetchCartFailure(result.errors));
      }
   }
};

export const advanceToNext = () => async (dispatch) => {
   dispatch(advanceToNextRequest());

   const result = await API.checkout.advanceToNext();

   if (result.success) {
      dispatch(advanceToNextSuccess(result.data.attributes));
   } else {
      const result = await API.cart.get();

      if (result.success) {
         dispatch(fetchCartSuccess(result.data));
      } else {
         dispatch(fetchCartFailure(result.errors));
      }
   }
};
export const finishCart = () => async (dispatch) => {
   destroyCookie('spsToken');
   destroyCookie('cartToken');

   dispatch(resetPayment());
   const result = await API.cart.finishCart();

   if (result.success) {
      dispatch(fetchCartSuccess(result.data));
   } else {
      dispatch(fetchCartFailure(result.error));
   }
};

export const addToCart =
   (isMobile, productDetail, successCallback, eventId) => async (dispatch, getState) => {
      const { variantSelected, quantity } = productDetail || getState().productDetail;

      dispatch(addToCartRequest());

      const result = await API.cart.addItem(variantSelected.id, quantity, eventId);

      if (result.success) {
         if (result.data.email && result.data.item_count > 0) {
            MA.add(result.data.email, result.data.token);
         }
         dispatch(addToCartSuccess(result.data));
         if (successCallback) successCallback();
         if (isMobile) {
            setTimeout(() => {
               dispatch(hideModalCart());
            }, 7000);
         }
      } else {
         dispatch(addToCartFailure(result.error));
      }
   };

export const setQuantityCart = (lineItemId, quantity) => async (dispatch) => {
   const result = await API.cart.setQuantityItem(lineItemId, quantity);
   const resultGet = await API.cart.get();
   const info = { ...result.data, ...resultGet.data };

   if (result.success) {
      dispatch(setQuantityCartSuccess(info));
   } else {
      dispatch(setQuantityCartFailure(result.error));
   }
};

export const removeItemCart = (lineItemId) => async (dispatch) => {
   const result = await API.cart.removeItem(lineItemId);

   if (result.success) {
      if (result.data.item_count < 1) {
         MA.remove();
      }
      dispatch(removeItemCartSuccess(lineItemId, result.data));
   } else {
      dispatch(removeItemCartFailure(result.error));
   }
};

export const setCuponCode = (code) => async (dispatch) => {
   const result = await API.cart.setCuponCode(code);

   if (result.success) {
      dispatch(setCuponCodeSuccess(result.data));
      return true;
   }
   dispatch(setCuponCodeFailure(result.errors));
   return false;
};

export const deleteCart = () => async (dispatch) => {
   dispatch({
      type: DELETE_CART,
   });
};
