import axiosDefault from 'axios';
import axios from '../../services/axiosInstance';
import { setCookie, destroyCookie, getCookies } from '../../services/cookies';
import sentry from '../../services/sentry';
import errorHandler from '../error/handler';

const storeId = process.env.NEXT_PUBLIC_STORE_ID;

const login = async (email, password) => {
   try {
      const response = await axiosDefault.post(
         `${process.env.NEXT_PUBLIC_BASE_URL}/spree_oauth/token`,
         {
            grant_type: 'password',
            username: email,
            password,
            code: storeId,
         },
      );

      const user = {
         name: response.data.username,
         email,
      };

      // guardamos el token en una cookie
      setCookie('token', response.data.access_token);

      // guardar refresh token
      setCookie('refreshToken', response.data.refresh_token);

      // si la respuesta tiene cartToken
      if (response.data.cartToken) {
         // buscamos el cartToken en las cookies
         const { cartToken } = getCookies();
         if (!cartToken) setCookie('cartToken', response.data.cartToken);
      }

      // guardamos el objeto user en el almacenamiento del navegador
      // de ahi podemos obtener el nombre y el email
      localStorage.setItem('user', JSON.stringify(user));

      return {
         success: true,
         data: user,
      };
   } catch (err) {
      const errors = errorHandler(err);

      return {
         success: false,
         errors,
      };
   }
};

const signup = async (user, eventId) => {
   try {
      await axios.post(`/account?isGuest=${user.isGuest}&event_id=${eventId}`, { user });
      return login(user.email, user.password);
   } catch (err) {
      const errors = errorHandler(err);

      return {
         success: false,
         errors,
      };
   }
};

const logout = async () => {
   destroyCookie('token');
   destroyCookie('refreshToken');
   destroyCookie('cartToken');
   localStorage.removeItem('user');
   localStorage.removeItem('marketingAutomationID');

   // reseteamos el usuario guardado en Sentry
   sentry.configureScope((scope) => scope.setUser(null));
};

const checkoutLogout = async () => {
   destroyCookie('token');
   destroyCookie('refreshToken');

   // reseteamos el usuario guardado en Sentry
   sentry.configureScope((scope) => scope.setUser(null));
};

const validateEmail = async (email, finish) => {
   try {
      const response = await axios.get(
         `/account/email_validation?email=${email}&code=${process.env.NEXT_PUBLIC_STORE_ID}${
            finish ? '&finish=true' : ''
         }`,
      );

      return {
         success: true,
         data: response.data,
      };
   } catch (err) {
      const errors = errorHandler(err);

      return {
         success: false,
         errors,
      };
   }
};

const guestEmail = async (email) => {
   try {
      const response = await axios.get(
         `${process.env.NEXT_PUBLIC_API_URL}/checkout/init_without_login?email=${email}&code=${storeId}`,
      );
      // guardamos el token en una cookie
      setCookie('token', response.data.access_token);

      // guardar refresh token
      setCookie('refreshToken', response.data.refresh_token);

      return {
         success: true,
         data: {
            username: response.data.username,
            email,
            persisted: response.data.persisted,
            has_previous_orders: response.data.has_previous_orders,
         },
      };
   } catch (err) {
      const errors = errorHandler(err);

      return {
         success: false,
         errors,
      };
   }
};

const receiveAuthCode = async (email, authCode) => {
   try {
      const response = await axios.get(
         `/account/receive_auth_code?email=${email}&authorization_code=${authCode}`,
      );

      const user = {
         name: response.data.username,
         email,
      };

      setCookie('token', response.data.access_token);

      setCookie('refreshToken', response.data.refresh_token);

      localStorage.setItem('user', JSON.stringify(user));

      return {
         success: true,
         data: user,
      };
   } catch (err) {
      return {
         success: false,
         errors: [err.response.data.data],
      };
   }
};

export default {
   login,
   signup,
   logout,
   validateEmail,
   guestEmail,
   checkoutLogout,
   receiveAuthCode,
};
